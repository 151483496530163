import { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useQuery } from 'apollo-client'

import { constants } from 'helpers'

import catalogueStructureQuery from './graph/catalogueStructure.graphql'
import type { CatalogueStructurePayload } from './graph/catalogueStructure.graphql'


type CataloguePayloadData = CatalogueStructurePayload['scentbirdCatalogue']['data']
type CatalogueBaseElement = Omit<CataloguePayloadData['children'][number]['children'][number], 'children'> & { children: CatalogueBaseElement[] }

const flatCatalogue = (data: CatalogueBaseElement[]): Record<string, number | string> => {
  if (!data) {
    return null
  }

  return data.reduce((result, child) => {
    const childId = child.catalogueKey || child.id
    const childName = typeof childId === 'string' ? child.catalogueKey : child.name

    result[childName] = childId

    if ('children' in child) {
      return {
        ...result,
        ...flatCatalogue(child.children),
      }
    }

    return result
  }, {})
}

export type UseCatalogueStructureProps = {
  skip?: boolean
}

const useCatalogueStructure = ({ skip = false }: UseCatalogueStructureProps) => {
  const isConstructorCatalogueEnabled = useFeatureIsOn(constants.features.constructorCatalogue)

  const { data, isFetching } = useQuery(catalogueStructureQuery, {
    variables: {
      isConstructorCatalogueEnabled,
    },
    fetchPolicy: 'cache-first',
    skip,
    context: {
      ssrCache: true,
    },
  })

  // top level structure is object: {
  //   name: "Main"
  //   children: [
  //     { name: "subscription", children: [...] },
  //     { name: "shop", children: [...] }
  //   ],
  // }
  // ATTN now it maps catalogue name to its id, change if you need more data
  const flatStructure = useMemo(() => {
    const constructorCatalogueFlat = flatCatalogue(data?.constructorCatalogue?.data?.children)
    const scentbirdCatalogueFlat = flatCatalogue(data?.scentbirdCatalogue?.data?.children)

    if (!constructorCatalogueFlat && !scentbirdCatalogueFlat) {
      return null
    }

    return {
      ...(constructorCatalogueFlat || {}),
      ...(scentbirdCatalogueFlat || {}),
    }
  }, [ data ])

  return {
    flatStructure,
    isFetching,
  }
}


export default useCatalogueStructure

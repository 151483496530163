import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { useSearchParams } from 'router'

import { getAddItemProduct } from 'helpers'
import { useIntl } from 'intl'
import { priceChannels } from 'modules/priceSelection'
import { useUser } from 'modules/user'

import { type UserGender } from 'typings/graphql'
import privateSaleQuery, { type PrivateSalePayload } from './graph/privateSale.graphql'


type PrivateSaleList = PrivateSalePayload['flashSaleList']['data']

export type PrivateSaleItem = PrivateSaleList[number] & {
  originalPrice: number
  addItemProduct: CartModule.AddProductInput['product']
}

const usePrivateSale = (props?: { detailed?: boolean }) => {
  const { detailed = false } = props || {}
  const intl = useIntl()
  const { isLoggedIn, gender: userGender, country } = useUser()
  const [ { gender: queryGender = '' } ] = useSearchParams()

  const { data, isFetching } = useQuery(privateSaleQuery, {
    skip: !isLoggedIn,
    variables: {
      input: {
        priceSelection: {
          country,
          channel: priceChannels.privateSale,
        },
      },
      detailed,
    },
    context: {
      ssrCache: true,
    },
  })

  // you can override main gender by query param `?gender`
  const gender = queryGender.toUpperCase() || userGender

  const { privateSaleList, privateSale } = useMemo(() => {
    if (!data?.flashSaleList?.data?.length) {
      return {}
    }

    const privateSaleList: PrivateSaleList = data.flashSaleList.data

    const salesMap = privateSaleList.reduce((result, sale) => {
      const addItemProduct: CartModule.AddProductInput['product'] =
        detailed
          ? getAddItemProduct({
            product: {
              id: sale.id,
              uid: sale.uid,
              rebrandImage: null,
              image: sale.image,
              name: sale.name,
              brandInfo: {
                name: '',
              },
              category: 'Flash Sale',
              type: 'FlashSale',
              theEdit: false,
              upchargePrice: null,
              isSubscribedToStockNotification: false,
              gender: sale.gender,
            },
            tradingItem: sale.tradingItem,
            intl,
          })
          : null

      result[sale.gender] = {
        ...sale,
        addItemProduct,
        originalPrice: sale.price + sale.discount,
      }
      return result
    }, {} as Record<UserGender, PrivateSaleItem>)

    const privateSale: PrivateSaleItem = salesMap[gender] || salesMap['UNISEX']

    return {
      privateSaleList,
      privateSale,
    }
  }, [ data, gender, detailed, intl ])

  return {
    privateSaleList,
    privateSale,
    gender,
    isFetching,
  }
}


export default usePrivateSale

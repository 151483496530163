import { useMemo } from 'react'
import { useQuery } from 'apollo-client'

import catalogueChildrenQuery from './graph/catalogueChildren.graphql'
import useCatalogueId from './useCatalogueId'


type UseAvailableCataloguesProps = {
  catalogueName: ProductsModule.CatalogueName
  excludeCatalogues?: string[]
  skip?: boolean
}

const useAvailableCatalogues = ({ catalogueName, excludeCatalogues = [], skip }: UseAvailableCataloguesProps) => {
  const { catalogueId, catalogueKey, isFetching: isCatalogueIdFetching } = useCatalogueId({ catalogueName, skip })

  const { data, isFetching: isAvailableCataloguesFetching } = useQuery(catalogueChildrenQuery, {
    variables: {
      catalogueId,
      catalogueKey,
      source: catalogueKey ? 'CONSTRUCTOR' : 'SCENTBIRD',
    },
    fetchPolicy: 'cache-first',
    skip: skip || !(catalogueId || catalogueKey),
    context: {
      ssrCache: true,
    },
  })

  const children = data?.catalogue?.data?.children

  const availableCatalogues = useMemo(() => {
    return children?.filter((child) => {
      const { name, products } = child

      return !excludeCatalogues.includes(name) && products?.count > 0
    })
  }, [ children, excludeCatalogues ])

  return {
    availableCatalogues,
    isFetching: isCatalogueIdFetching || isAvailableCataloguesFetching,
  }
}


export default useAvailableCatalogues
